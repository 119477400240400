/* Provide sufficient contrast against white background */

code {
    color: #e01a76;
}

.cursor-pointer {
    cursor: pointer;
}

.px-2h {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
}

.sv_body {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.sv_main button.select2-selection__choice__remove {
    min-width: 0 !important;
}

.select2-container--classic .select2-selection--multiple,
.select2-container--classic .select2-selection--multiple:focus,
.select2-container--classic.select2-container--open .select2-dropdown {
    border-radius: 0 !important;
    border-color: rgb(231, 231, 231) !important;
}

.sv_q {
    width: 100%;
}

.select2-results__option--selected {
    display: none;
}
