.animate-width {
  transition: 0.4s width ease;

  width: 100%;
  overflow: hidden;
}

.animate-width.hide-panel {
  width: 0 !important;
}
