/*! `Custom` Bootstrap 5 theme */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700);
$font-family-base: Source Sans Pro;
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700);
$headings-font-family: Source Sans Pro;

$blue: #ed823e;
$green: #198754;

$secondary: #5fddaa;
$dark: #1f2535;

$font-weight-bold: 600;

@import "~bootstrap/scss/bootstrap";

[list]::-webkit-calendar-picker-indicator {
  display: block;
}

.rc_single_highlight {
  border: $secondary solid 2px;
  border-radius: 4px;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.v-hidden {
  visibility: hidden;
}

.text-subtle {
  font-size: small;
  color: lighten($dark, 50%);
  text-transform: uppercase;
}

.text-disabled {
  color: lighten($dark, 50%);
}

.btn-secondary-outline {
  color: $secondary !important;
  border-color: $secondary !important;
  background-color: rgba(255, 255, 255, 0) !important;

  transition: 0.2s all ease-in-out;
  box-shadow: none !important;
}

.btn-secondary-outline:hover {
  color: $light !important;
  border-color: $primary !important;
  background-color: $primary !important;
}

.btn-secondary-outline:active {
  border-color: $dark !important;
  background-color: $dark !important;
}

.btn-primary-outline {
  color: $primary !important;
  border-color: $primary !important;
  background-color: rgba(255, 255, 255, 0) !important;

  transition: 0.2s all ease-in-out;
  box-shadow: none !important;
}

.btn-primary-outline:hover {
  color: $light !important;
  background-color: $primary !important;
}

.btn-primary-outline:active {
  border-color: $dark !important;
  background-color: $dark !important;
}

.btn-info-outline {
  color: $info !important;
  border-color: $info !important;
  background-color: rgba(255, 255, 255, 0) !important;

  transition: 0.2s all ease-in-out;
  box-shadow: none !important;
}

.btn-info-outline:hover {
  color: $light !important;
  border-color: $primary !important;
  background-color: $primary !important;
}

.btn-info-outline:active {
  border-color: $dark !important;
  background-color: $dark !important;
}

// Date picker overrides
.react-datepicker,
.react-datepicker-wrapper {
  border-radius: 0 !important;
  font-family: $font-family-base !important;
}

.react-datepicker__header {
  user-select: none !important;
}

.react-datepicker__navigation {
  margin-top: 6px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0 !important;
}

.react-datepicker-ignore-onclickoutside:focus-visible {
  outline: none !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected :hover,
.react-datepicker__day--selected,
.react-datepicker__day--selected :hover {
  border-radius: 0 !important;
  font-weight: normal !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: rgb(28, 126, 212) !important;
}
